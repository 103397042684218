import React, { useEffect, useState } from 'react';

const ImageConverter = ({ filePath, altText }) => {
  const [convertedSrc, setConvertedSrc] = useState('');

  useEffect(() => {
    const convertToJpg = async () => {
      const img = new Image();
      img.crossOrigin = 'anonymous';  // Enables loading from a different origin
      img.src = `${filePath}`;  // Original file path without extension

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        // Convert canvas to a data URL in .jpg format
        const jpgDataUrl = canvas.toDataURL('image/jpeg');
        setConvertedSrc(jpgDataUrl);
      };

      img.onerror = () => {
        console.error('Failed to load image for conversion');
      };
    };

    convertToJpg();
  }, [filePath]);

  return (
    <img height="200px" width="100%" alt={altText} src={convertedSrc} />
  );
};

export default ImageConverter;
