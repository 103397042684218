// import * as React from 'react';
import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { headerData } from "../data/headerCostum";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@mui/material";
import SweetAlert2 from "react-sweetalert2";
import { useLocalStorage } from "../auth/useLocalStorage";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="#FFFFFF"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://makassarkota.go.id/">
        Pemerintah Kota Makassar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const CekLongwis = () => {
  const navigate = useNavigate();

  const [swalProps, setSwalProps] = useState({});
  const [data, setData] = useState({
    id: null,
    kecamatan: null,
    kelurahan: null,
    longwis: null,
    alamat: null,
    nama_pic: '',
    kontak_pic: '',
    tanggal: null,
    lokasi: [0, 0],
    status: ''
  });

  const [kecamatan, setKecamatan] = useState([{
    id: 1,
    nama: 'Mohon Tunggu . . .'
  }]);

  const [kelurahan, setKelurahan] = useState([{
    id: 1,
    nama: 'Mohon Tunggu . . .'
  }]);

  const [data_longwis, setDataLongwis] = useState([{
    id: 1,
    nama: 'Mohon Tunggu . . .'
  }]);

  const [list_longwis, setListLongwis] = useState(null)

  const [loklongwis, setLokLongwis] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (event) => {
    event.preventDefault();
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getLongwisData = () => {
    fetch(process.env.REACT_APP_API_URL + "api/longwis", {
      method: "get",
      headers: headerData
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res) {
          setListLongwis(res);
          getKecData(res)
        } else {
          setSwalProps({
            show: true,
            title: "Gagal",
            icon: "error",
            text: res.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getKecData = useCallback((data) => {
    let kecamatan = [];
    let dataKecamatan = [];
    data.forEach((items) => {
      let indx = 0
      if (kecamatan.includes(items.kecamatan)) {
        return
      } else {
        kecamatan.push(items.kecamatan)
        dataKecamatan.push({ nama: items.kecamatan, id: indx });
        indx += 1;
      }
    })
    setKecamatan(dataKecamatan)
  }, [setKecamatan])

  const kecHandle = useCallback((e) => {
    if (e) {
      const filteredData = [...list_longwis].filter(item => item.kecamatan === e.nama);
      let kelurahan = [];
      let dataKelurahan = [];
      filteredData.forEach((items) => {
        let indx = 0
        if (kelurahan.includes(items.kelurahan)) {
          return
        } else {
          kelurahan.push(items.kelurahan)
          dataKelurahan.push({ nama: items.kelurahan, id: indx });
          indx += 1;
        }
      })
      setKelurahan(dataKelurahan);
    }
  }, [list_longwis, setKelurahan]);

  const kelHandle = useCallback((e) => {
    if (e) {
      const filteredData = [...list_longwis].filter(item => item.kelurahan === e.nama);
      let longwis = [];
      let dataLongwis = [];
      filteredData.forEach((items) => {
        let indx = 0
        if (longwis.includes(items.nama_longwis)) {
          return
        } else {
          longwis.push(items.kelurahan)
          dataLongwis.push({ nama: items.nama_longwis, id: indx });
          indx += 1;
        }
      })
      setDataLongwis(dataLongwis);
    }
  }, [list_longwis, setDataLongwis]);

  const alamatHanlde = useCallback(async (e) => {
    if (e) {
      const filteredData = [...list_longwis].filter(item => item.nama_longwis === e.nama);
      let pic = '';
      let kontak_pic = '';
      let id = '';
      let alamat_longwis = '';
      let loklongwi = null;
      let lokasi = [];
      await filteredData.forEach((items) => {
        pic = items.nama_pic;
        kontak_pic = items.kontak_pic;
        id = items.id;
        alamat_longwis = items.alamat;
        loklongwi = items.LokLongwi;
        lokasi = JSON.parse(items.lokasi);
      })
      setLokLongwis(loklongwi);
      setData({ ...data, id: id, nama_pic: pic, kontak_pic: kontak_pic, alamat: alamat_longwis, lokasi: lokasi });
    }
  }, [list_longwis, data, setData, setLokLongwis]);

  const sendData = () => {
    setSwalProps({
      show: true,
      title: "Sukses",
      icon: "success",
      text: "Data telah ditambahkan",
    });
    setOpenDialog(false);
  };



  useEffect(() => {
    if (!list_longwis) {
      getLongwisData();
    }
  }, [list_longwis, getLongwisData]);

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        // backgroundImage: `linear-gradient(180deg, rgba(146, 28, 32, 1), rgba(0, 0, 0, 1));`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        position: "absolute",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container sx={{ paddingX: 0 }} component="main" maxWidth="xs">
          <CssBaseline />
          <SweetAlert2
            {...swalProps}
            didClose={() => {
              setSwalProps({});
            }}
          />
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              align="center"
              sx={{ justifyItems: "center" }}
              id="alert-dialog-title"
            >
              Konfirmasi
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Apakah anda yakin bahwa data yang dimasukkan telah sesuai ?
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                onClick={handleCloseDialog}
                sx={{
                  background: "#B40000",
                }}
              >
                Kembali
              </Button>
              <Button
                variant="contained"
                onClick={sendData}
                autoFocus
                sx={{
                  background: "#4985F8",
                }}
              >
                Yakin
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                marginTop: 5,
              }}
            >
              <Typography textTransform={"uppercase"} fontWeight={700}>
                {"PILIH Longwis"}
              </Typography>
              {/* <img src={MainFlyer} alt="Main Flyer" width={"100%"} /> */}
            </Box>
            <Box
              component="form"
              onSubmit={handleOpenDialog}
              sx={{ mt: 1, paddingX: 2 }}
            >
              <Autocomplete
                disablePortal
                id="kecamatan"
                options={
                  kecamatan ? kecamatan : [{ nama: "loading data mohon tunggu.." }]
                }
                value={data.kecamatan}
                getOptionLabel={(option) => option.nama}
                onChange={(event, newValue) => {
                  kecHandle(newValue);
                  setData({ ...data, kecamatan: newValue });
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="Kecamatan"
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 2,
                    }}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                id="kelurahan"
                options={
                  kelurahan ? kelurahan : [{ id: 1, nama: "loading data mohon tunggu.." }]
                }
                value={data.kelurahan}
                getOptionLabel={(option) => option.nama}
                onChange={(event, newValue) => {
                  // setOpd(newValue);
                  kelHandle(newValue);
                  setData({ ...data, kelurahan: newValue });
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="Kelurahan"
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 2,
                    }}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                id="longwis"
                options={
                  data_longwis ? data_longwis : [{ id: 1, nama: "loading data mohon tunggu.." }]
                }
                value={data.data_longwis}
                getOptionLabel={(option) => option.nama}
                onChange={(event, newValue) => {
                  alamatHanlde(newValue)
                  setData({ ...data, longwis: newValue });
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="Nama Lorong Wisata"
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 2,
                    }}
                  />
                )}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="alamat"
                placeholder="Alamat"
                name="alamat"
                disabled
                value={data.alamat}
                // onChange={(event) => setData({ ...data, nama_pic: event.target.value })}
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="nama_pic"
                placeholder="Nama PIC"
                name="nama_pic"
                disabled
                value={data.nama_pic}
                // onChange={(event) => setData({ ...data, nama_pic: event.target.value })}
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                type="number"
                id="kontak_pic"
                placeholder="Kontak PIC"
                name="kontak_pic"
                disabled
                value={data.kontak_pic}
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              {
                loklongwis && (
                  <Grid container direction={"column"} alignContent={'center'}>
                    <Grid item>
                      <iframe src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyDdiIytgxmGyRmnGV3_0rBmKPQWU9M7Gkk&q=" + data.lokasi[0] + "," + data.lokasi[1]}
                        width={"100%"}
                        height={"300"}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                      </iframe>
                    </Grid>
                    <Grid>
                      {
                        JSON.parse(loklongwis.file_foto).map((items) => 
                          <img
                            src={process.env.REACT_APP_API_URL+'uploads/lokasi/'+items}
                            alt={items}
                            width="100%"
                            style={{ marginTop: 2 }}
                          />
                        )
                      }
                    </Grid>
                  </Grid>
                )
              }
              <Button
                fullWidth
                variant="contained"
                onClick={() => navigate('/')}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "rgba(65, 178, 87, 1)",
                  textTransform: "none",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  ":hover": {
                    backgroundColor: "rgba(65, 178, 87, 0.7)",
                  },
                }}
                size="medium"
              >
                {'Kembali'}
              </Button>
            </Box>
            <Box sx={{ fontSize: 14, color: "#FFFFFF", textAlign: "center" }}>
              {/* Pemerintah Kota Makassar 2024 */}
              {/* <Box>
                Silahkan cek
                <Button
                  sx={{ color: "#FFF000", textDecoration: "underline" }}
                  onClick={() => navigate("cekqr")}
                >
                  di SINI
                </Button>
              </Box> */}
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default CekLongwis;
