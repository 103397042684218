import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../auth/auth_provider';
import { headerData } from '../data/headerCostum';
import Logo from '../assets/image/logo.png';
import { useNavigate } from 'react-router-dom';

function Copyright(props) {
    return (
        <Typography variant="body2" color="darkslategray" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
                Diskominfo Kota Makassar
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const HalLoginPIC = () => {
    const { loginPIC } = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();


    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage(''); // Clear any existing error messages
        const data = new FormData(event.currentTarget);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/pic/login`, {
                method: 'POST',
                headers: headerData,
                body: JSON.stringify({
                    username: data.get('username'),
                    password: data.get('password'),
                }),
            });
            const result = await response.json();

            if (response.ok && result.token) {
                loginPIC(result);
                console.log(result);
            } else {
                setErrorMessage(result.message || 'Login failed. Please check your credentials.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
            console.error('Error during login:', error);
        }
    };

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'right bottom',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
            }}
        >
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100vh',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <Box marginBottom={2}>
                            <img src={Logo} alt="Main Logo" width="100%" />
                        </Box>
                        <Typography fontSize={17} fontWeight={700}>
                            CCTV LONGWIS
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ mt: 2, width: '100%', paddingX: 2 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                name="username"
                                placeholder="Username"
                                autoComplete="username"
                                autoFocus
                                size="small"
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: 2,
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                placeholder="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                size="small"
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: 2,
                                }}
                            />
                            {errorMessage && (
                                <Typography color="error" variant="body2" align="center" sx={{ mt: 1 }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    background: 'linear-gradient(90deg, rgba(29, 195, 159, 1), rgba(143, 249, 171, 1))',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    color: '#FFFFFF',
                                    ':hover': {
                                        backgroundColor: 'rgba(65, 178, 87, 0.7)',
                                    },
                                }}
                                size="medium"
                            >
                                Masuk
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => navigate('cekcctv')}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    background: 'linear-gradient(90deg, rgba(29, 195, 159, 1), rgba(143, 249, 171, 1))',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    color: '#FFFFFF',
                                    ':hover': {
                                        backgroundColor: 'rgba(65, 178, 87, 0.7)',
                                    },
                                }}
                                size="medium"
                            >
                                Cek CCTV
                            </Button>
                        </Box>
                        <Box mt={2}>
                            <Copyright />
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </Box>
    );
};

export default HalLoginPIC;
