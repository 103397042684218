import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useLocalStorage } from '../../auth/useLocalStorage';
import { headerData } from '../../data/headerCostum';
import { useReactToPrint } from 'react-to-print';

const PrintPDF = ({ execute }) => {
  const [longwis] = useLocalStorage("longwis");
  const [dataLongwis, setDataLongwis] = useState(null);
  const [dokumentasiCCTV, setdokumentasiCCTV] = useState(null);
  const [dokumentasiNVR, setdokumentasiNVR] = useState(null);
  const [dokumentasiHarddisk, setdokumentasiHarddisk] = useState(null);
  const [dokumentasiSwitch, setdokumentasiSwitch] = useState(null);
  const [dokumentasiLayar, setdokumentasiLayar] = useState(null);
  const [dokumentasiSistem, setdokumentasiSistem] = useState(null);


  const [downloaded, setDownloaded] = useState(false);

  const pdfRef = useRef();

  const DownloadPDF = useReactToPrint({
    content: () => pdfRef.current,
  })


  const statusConverter = (status, type) => {
    // <MenuItem value={2}>Fisik Terpasang Semua</MenuItem>
    //               <MenuItem value={1}>Fisik Terpasang Sebagian</MenuItem>
    //               <MenuItem value={0}>Fisik Belum Terpasang</MenuItem>

    // <MenuItem value={1}>Tampilan Lengkap</MenuItem>
    //               <MenuItem value={0}>Tampilan Tidak Lengkap</MenuItem>

    // <MenuItem value={1}>Tampilan System Online</MenuItem>
    //               <MenuItem value={0}>Tampilan System Offline</MenuItem>
    if (type === "tampilan") {
      switch (status) {
        case 0: return "Tampilan Tidak Lengkap";
        case 1: return "Tampilan Lengkap";
        default: return "";
      }
    } if (type === "sistem") {
      switch (status) {
        case 0: return "Tampilan System Offline";
        case 1: return "Tampilan System Online";
        default: return "";
      }
    } else {
      switch (status) {
        case 0: return "Fisik Belum Terpasang";
        case 1: return "Fisik Terpasang Sebagian";
        case 2: return "Fisik Terpasang Semua";
        default: return "";
      }
    }

  }

  // const formatDate = (value) => {
  //   const date_time = new Date(value);
  //   return (date_time.toISOString().split('T')[0]);
  // }


  const getLaporanData = useCallback(() => {
    fetch(process.env.REACT_APP_API_URL + "api/longwis/" + longwis.id, {
      method: "get",
      headers: headerData
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.nama_longwis) {
          setDataLongwis(res);
          setdokumentasiCCTV(JSON.parse(res.DataCCTV.file_foto));
          setdokumentasiNVR(JSON.parse(res.DataNVR.file_foto));
          setdokumentasiHarddisk(JSON.parse(res.Harddisk.file_foto));
          setdokumentasiSwitch(JSON.parse(res.Switch.file_foto));
          setdokumentasiLayar(JSON.parse(res.DataTampilanLayar.file_foto));
          setdokumentasiSistem(JSON.parse(res.DataTampilanSystem.file_foto));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [longwis.id]);

  useEffect(() => {
    if (!dataLongwis) {
      getLaporanData()
    } else {
      if (execute && !downloaded) {
        DownloadPDF();
        setDownloaded(true);
      }
    }
  }, [dataLongwis, getLaporanData, DownloadPDF, downloaded, execute])

  return (
    <div ref={pdfRef}>
      <Grid container width={"297mm"}>
        {/* <Grid item container direction={"row"} sx={{
          marginX: 20,
          marginY: 5
        }}>
          <Grid item container xs={3} justifyContent={"center"} >
            <img src={Logo} width={110} alt='Logo Pemkot' />
          </Grid>
          <Grid item xs={9} container direction={'column'} alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Typography variant='h3' fontSize={35} textTransform={'uppercase'} fontWeight={'700'}>
                {"Pemerintah Kota Makassar"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h5' fontSize={19} align='center' textTransform={'uppercase'} fontWeight={'700'}>
                {"Dinas Komunikasi dan Informatika Kota Makassar"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>
                {"Jalan Andi Pangeran Pettarani No. 62 Makassar 9032"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>
                {"Email: diskolminfo@makassarkota.go.id, HomePage: diskominfo.makassarkota.go.id"}
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item sx={{
          background: "GrayText",
          height: "2px",
          width: "100%",
          marginBottom: 1,
          marginX: 10,
        }}>
          <Divider />
        </Grid>
      </Grid>
      {/* <Box height={50} sx={{
      pageBreakBefore: "always"
    }} /> */}
      {
        dataLongwis
          ? <Grid item width={"297mm"} container paddingX={10}>
            <Grid container >
              <Grid item container spacing={2}>
                <Grid item>
                  Nama PIC:  {dataLongwis.nama_pic}
                </Grid>
                <Grid item>
                  No Telp PIC:  {dataLongwis.kontak_pic}
                </Grid>
                <Grid item>
                  Nama Longwis:  {dataLongwis.nama_longwis}
                </Grid>
                <Grid item>
                  Kecamatan: {dataLongwis.kecamatan}
                </Grid>
                <Grid item>
                  Kelurahan: {dataLongwis.kelurahan}
                </Grid>
                <Grid item>
                  Alamat: {dataLongwis.alamat}
                </Grid>
                <Grid item>
                  Tanggal Pengecekan:
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{
              background: "GrayText",
              height: "2px",
              width: "100%",
              marginBottom: 1,
              marginX: 10,
            }}></Grid>
            <TableContainer sx={{
              marginTop: 2
            }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>
                      <Typography fontWeight={700}>{"Jenis Pengecekan"}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography fontWeight={700}>{"Status"}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography fontWeight={700}>{"Keterangan"}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography fontWeight={700}>{"Dokumentasi"}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>
                        {'CCTV'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {statusConverter(parseInt(dataLongwis.DataCCTV.status_fisik))}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {dataLongwis.DataCCTV.keterangan}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container direction={"row"} spacing={1}>
                        {
                          dokumentasiCCTV
                            ? dokumentasiCCTV.map(element => {
                              return (
                                <Grid item xs={6}>
                                  <img height={"150px"} width={"100%"} alt={element} src={process.env.REACT_APP_API_URL + 'uploads/cctv/' + element} />
                                </Grid>
                              );
                            })
                            : <></>
                        }
                      </Grid>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>
                        {'NVR'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {statusConverter(parseInt(dataLongwis.DataNVR.status_fisik))}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {dataLongwis.DataNVR.keterangan}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container direction={"row"} spacing={1}>
                        {
                          dokumentasiNVR
                            ? dokumentasiNVR.map(element => {
                              return (
                                <Grid item xs={6}>
                                  <img height={"150px"} width={"100%"} alt={element} src={process.env.REACT_APP_API_URL + 'uploads/nvr/' + element} />
                                </Grid>
                              );
                            })
                            : <></>
                        }
                      </Grid>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>
                        {'Harddisk'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {statusConverter(parseInt(dataLongwis.Harddisk.status_fisik))}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {dataLongwis.Harddisk.keterangan}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container direction={"row"} spacing={1}>
                        {
                          dokumentasiHarddisk
                            ? dokumentasiHarddisk.map(element => {
                              return (
                                <Grid item xs={6}>
                                  <img height={"150px"} width={"100%"} alt={element} src={process.env.REACT_APP_API_URL + 'uploads/harddisk/' + element} />
                                </Grid>
                              );
                            })
                            : <></>
                        }
                      </Grid>
                    </TableCell>
                  </TableRow>


                  <TableRow>
                    <TableCell>
                      <Typography>
                        {'Switch'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {statusConverter(parseInt(dataLongwis.Switch.status_fisik))}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {dataLongwis.Switch.keterangan}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container direction={"row"} spacing={1}>
                        {
                          dokumentasiSwitch
                            ? dokumentasiSwitch.map(element => {
                              return (
                                <Grid item xs={6}>
                                  <img height={"150px"} width={"100%"} alt={element} src={process.env.REACT_APP_API_URL + 'uploads/switch/' + element} />
                                </Grid>
                              );
                            })
                            : <></>
                        }
                      </Grid>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>
                        {'Layar'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {statusConverter(parseInt(dataLongwis.DataTampilanLayar.status_tampilan), 'tampilan')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {dataLongwis.DataTampilanLayar.keterangan}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container direction={"row"} spacing={1}>
                        {
                          dokumentasiLayar
                            ? dokumentasiLayar.map(element => {
                              return (
                                <Grid item xs={6}>
                                  <img height={"150px"} width={"100%"} alt={element} src={process.env.REACT_APP_API_URL + 'uploads/tampilan/' + element} />
                                </Grid>
                              );
                            })
                            : <></>
                        }
                      </Grid>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>
                        {'Sistem'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {statusConverter(parseInt(dataLongwis.DataTampilanSystem.status_tampilan), 'sistem')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {dataLongwis.DataTampilanSystem.keterangan}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container direction={"row"} spacing={1}>
                        {
                          dokumentasiSistem
                            ? dokumentasiSistem.map(element => {
                              return (
                                <Grid item xs={6}>
                                  <img height={"150px"} width={"100%"} alt={element} src={process.env.REACT_APP_API_URL + 'uploads/sistem/' + element} />
                                </Grid>
                              );
                            })
                            : <></>
                        }
                      </Grid>
                    </TableCell>
                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          : <></>
      }
    </div>
  );
}

export default PrintPDF;
