import React, { Suspense } from "react";
import { Alert, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import {
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
  defer,
  Route,
  useOutlet,
  useLoaderData,
  Await,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./auth/auth_provider";
// import { AuthProvider, useAuth } from './auth/auth_provider';
import Pendaftaran from "./scenes/pendaftaran/daftar";
import Daftarsukses from "./scenes/pendaftaran/sukses";
import Login from "./scenes/login";
import SplashScreen from "./scenes/global/spashscreen";
import DaftarUser from "./scenes/admin/akun";
import Dashboard from "./scenes/admin/dashboard";
import SideMenu from "./scenes/global/Menu";
import Pendaftar from "./scenes/admin/pendaftar";

import Konten from "./scenes/pendaftaran/konten";
import LoginUser from "./scenes/pendaftaran/login";
import DaftarPIC from "./scenes/admin/pci";
import HalLoginPIC from "./scenes/login_pic";
import LaporanLongwis from "./scenes/pic/daftar_longwis";
import Layout_pic from "./scenes/pic/global/layout_pic";
import LaporanCCTV from "./scenes/pic/daftar_cctv";
import LaporanNVR from "./scenes/pic/daftar_nvr";
import Laporanlayar from "./scenes/pic/daftar_layar";
import Laporansistem from "./scenes/pic/daftar_sistem";
import PrintPDF from "./scenes/pic/download_laporan";
import PrintLampiran from "./scenes/pic/lampiran_laporan";
import DaftarLaporan from "./scenes/pic/daftar_laporan";
import LaporanLongwisPenyedia from "./scenes/pic/daftar_longwis_penyedia";
import LaporanHarddisk from "./scenes/pic/daftar_hardisk";
import LaporanSwitch from "./scenes/pic/daftar_switch";
import DownloadLaporan from "./scenes/pic/download_laporan";
import ListLongwis from "./scenes/admin/pendaftar";
import PrintPDFAdmin from "./scenes/admin/pendaftar/download_laporan";
import HalPilihLayanan from "./scenes/pic/pilihan_layanan";
import LaporanLongwisLokasi from "./scenes/pic/daftar_longwis_lokasi";
import DaftarLaporanLokasi from "./scenes/pic/daftar_laporan_lokasi";
import LaporanCekLokasi from "./scenes/pic/daftar_ceklokasi";
import PrintPDFAdminLokasi from "./scenes/admin/pendaftar/download_lokasi";
import LaporanLongwisInternet from "./scenes/pic/daftar_longwis_internet";
import LaporanInternet from "./scenes/pic/daftar_internet";
import LongwisInternet from "./scenes/admin/longwis_internet";
import PrintPDFAdminInternet from "./scenes/admin/longwis_internet/download_lokasi";
import CekLongwis from "./scenes/cek_longwis";

const App = () => {
  const [theme, colorMode] = useMode();
  // const { user } = useAuth();
  const router = createHashRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >
        <Route index element={<HalLoginPIC />} />
        <Route path="login" element={<Login />} />
        <Route path="masuk" element={<HalLoginPIC />} />
        <Route path="cekcctv" element={<CekLongwis />} />
        <Route path="download/:id"  element={<PrintPDFAdmin />} />
        <Route path="download/lokasi/:id"  element={<PrintPDFAdminLokasi />} />
        <Route path="download/internet/:id"  element={<PrintPDFAdminInternet />} />
        <Route path="admin" element={<ProtectedLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="rekapan" element={<ListLongwis />} />
          <Route path="internet" element={<LongwisInternet />} />
          <Route path="pendaftar" element={<Pendaftar />} />
          <Route path="daftaruser" element={<DaftarUser />} />
          <Route path="daftarpic" element={<DaftarPIC />} />
        </Route>
        <Route path="pic" element={<ProtectedLayoutPIC />}>
          <Route index element={<HalPilihLayanan />} />
          <Route path="layanan" element={<HalPilihLayanan />} />
          <Route path="longwis" element={<LaporanLongwis />} />
          <Route path="longwis-lokasi" element={<LaporanLongwisLokasi />} />
          <Route path="internetlongwis" element={<LaporanLongwisInternet />} />
          <Route path="internet" element={<LaporanInternet />} />
          <Route path="lokasi" element={<LaporanCekLokasi />} />
          <Route path="cctv" element={<LaporanCCTV />} />
          <Route path="nvr" element={<LaporanNVR />} />
          <Route path="harddisk" element={<LaporanHarddisk />} />
          <Route path="switch" element={<LaporanSwitch />} />
          <Route path="layar" element={<Laporanlayar />} />
          <Route path="sistem" element={<Laporansistem />} />
          <Route path="laporan" element={<DaftarLaporan />} />
          <Route path="laporan-lokasi" element={<DaftarLaporanLokasi />} />
          <Route path="download/:type" element={<PrintLampiran />} />
        </Route>
        <Route path="penyedia" element={<ProtectedLayoutPenyedia />}>
          <Route index element={<LaporanLongwisPenyedia />} />
          <Route path="longwis" element={<LaporanLongwisPenyedia />} />
        </Route>
      </Route>
    )
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const getUserData = () =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      const pic = window.localStorage.getItem("pic");
      resolve(user);
      resolve(pic);
      reject("Error");
    }, 3000)
  );

const ProtectedLayout = () => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <SideMenu />
    </div>
  );
};

const ProtectedLayoutPIC = () => {
  const { pic } = useAuth();
  if (!pic) {
    return window.location.reload();
  }
  return (
    <div>
      <Layout_pic />
    </div>
  );
};

const ProtectedLayoutPenyedia = () => {
  const { penyedia } = useAuth();
  if (!penyedia) {
    return window.location.reload();
  }
  return (
    <div>
      <Layout_pic />
    </div>
  );
};

const AuthLayout = () => {
  const oulet = useOutlet();
  const { userPromise } = useLoaderData();

  return (
    <Suspense fallback={<SplashScreen />}>
      <Await
        resolve={userPromise}
        errorElement={
          <Alert severity="error">
            404: Halaman yang anda Cari tidak tersedia
          </Alert>
        }
        children={(user) => (
          <AuthProvider userData={user}>{oulet}</AuthProvider>
        )}
      />
    </Suspense>
  );
};

export default App;
