import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import PrintPDF from './download_laporan';
import { useParams } from 'react-router-dom';
import PrintPDFLokasi from './download_laporan_lokasi';

// const data = {
//   nama_pic: "Muhammad Rezky",
//   kontak_pic: "0895805040352",
//   nama_longwis: "Buger",
//   kecamatan: "Bontoala",
//   kelurahan: "Prang Layang",
//   alamat: "Jln. Hj. Manila Dg. Pati No. 32",
//   tanggal: "2024-05-29",
//   status: "Sedang Dikerjakan",
//   status_fisik_cctv: "1",
//   status_fisik_nvr: "1",
//   status_tampilan_system: "1",
//   status_tampilan_layar: "1"
// }

const PrintLampiran = () => {
  const pdfRef = useRef();
  const [execute, setExecute] = useState(false)
  let { type } = useParams();

  useEffect(() => {
    // DownloadPDF()
    setExecute(true)
    // window.close();
  }, []);

  return (
    <Grid container justifyContent={"center"} direction={"column"}>
      <Grid item container>
        <Box component={"div"} ref={pdfRef} sx={{ overflow: "auto", height: "auto" }}>
          {type==="lokasi" ? <PrintPDFLokasi execute={execute} /> : <PrintPDF execute={execute} /> }
        </Box>
      </Grid>
    </Grid>
  );
};

export default PrintLampiran;
