import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  LinearProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SweetAlert2 from "react-sweetalert2";
import { useAuth } from "../../auth/auth_provider";
import { useLocalStorage } from "../../auth/useLocalStorage";
import Compressor from "compressorjs";
import { useLocation, useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography variant="body2" color="#FFFFFF" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://makassarkota.go.id/">
        Pemerintah Kota Makassar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

const LaporanInternet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [swalProps, setSwalProps] = useState({});
  const [progress, setProgress] = useState(0);
  const [successSend, setSuccess] = useState(null);
  const { data_longwis }  = location.state;
  const { pic } = useAuth();

  const [data, setData] = useState({
    status_fisik: "",
    serial_number: "",
    foto_ont: null,
    foto_qr: null,
    foto_no_seri: null,
  });

  const uploadFile = (file, key) => {
    new Compressor(file, {
      quality: 0.7,
      maxWidth: 450,
      success(result) {
        setData({ ...data, [key]: result });
      },
      error(err) {
        console.log(err);
      },
    });
  };

  const packFiles = async () => {
    const form_data = new FormData();
    form_data.append("foto_ont", data.foto_ont);
    form_data.append("foto_no_seri", data.foto_no_seri);
    form_data.append("foto_qr", data.foto_qr);
    form_data.append("serial_number", data.serial_number);
    form_data.append("status", data.status_fisik);
    form_data.append("id_user", pic.user.id);
    form_data.append("id_longwis", data_longwis.id);

    return form_data;
  };

  const sendData = async (event) => {
    event.preventDefault();
    const file_data = await packFiles();

    axios
      .post(process.env.REACT_APP_API_URL + "api/internet", file_data, {
        headers: {
          "Authorization": process.env.REACT_APP_API_AUTH,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );
          if (totalLength) {
            setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      })
      .then(async (res) => {
        if (res.status === 200) {
          setSuccess(true);
          await setSwalProps({
            show: true,
            title: "Sukses",
            icon: "success",
            text: "Data telah ditambahkan",
          });
        } else {
          setSwalProps({
            show: true,
            title: "Gagal",
            icon: "error",
            text: res.data.message,
          });
          return;
        }
      })
      .catch((err) => {
        setSwalProps({
          show: true,
          title: "Error",
          icon: "error",
          text: "Terjadi kesalahan saat mengirim data",
        });
      });
  };

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        position: "absolute",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container sx={{ paddingX: 0 }} component="main" maxWidth="xs">
          <CssBaseline />
          <SweetAlert2
            {...swalProps}
            didClose={() => {
              setSwalProps({});
            }}
            onConfirm={() => {
              if(successSend){
                navigate("/pic/internetlongwis")
              }
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "100vh",
            }}
          >
            <Box sx={{ marginTop: 5 }}>
              <Typography>{"Pengecekan Internet"}</Typography>
            </Box>
            <Box
              component="form"
              onSubmit={sendData}
              sx={{ mt: 1, paddingX: 2 }}
            >
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel size="small" id="select-type-user">
                  {"Status Internet"}
                </InputLabel>
                <Select
                  labelId="select-type-user"
                  id="type-user"
                  value={data.status_fisik}
                  required
                  label="Status Internet"
                  size="small"
                  onChange={(e) =>
                    setData({ ...data, status_fisik: e.target.value })
                  }
                >
                  <MenuItem value={2}>Internet Online</MenuItem>
                  <MenuItem value={1}>Internet Offline</MenuItem>
                </Select>
              </FormControl>

              {/* Upload Foto ONT */}
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <Input
                  accept="image/*"
                  id="upload-ont"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={(e) => uploadFile(e.target.files[0], "foto_ont")}
                />
                <label htmlFor="upload-ont">
                  <Button variant="contained" size="small" component="span">
                    Upload Foto ONT Depan
                  </Button>
                </label>
                {data.foto_ont && (
                  <img
                    src={URL.createObjectURL(data.foto_ont)}
                    alt="ONT Preview"
                    width="100%"
                    style={{ marginTop: 10 }}
                  />
                )}
              </FormControl>

              {/* Upload Foto Serial Number */}
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <Input
                  accept="image/*"
                  id="upload-serial"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={(e) =>
                    uploadFile(e.target.files[0], "foto_no_seri")
                  }
                />
                <label htmlFor="upload-serial">
                  <Button variant="contained" size="small" component="span">
                    Upload ONT Belakang
                  </Button>
                </label>
                {data.foto_no_seri && (
                  <img
                    src={URL.createObjectURL(data.foto_no_seri)}
                    alt="Serial Number Preview"
                    width="100%"
                    style={{ marginTop: 10 }}
                  />
                )}
              </FormControl>

              {/* Upload Foto QR */}
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <Input
                  accept="image/*"
                  id="upload-qr"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={(e) => uploadFile(e.target.files[0], "foto_qr")}
                />
                <label htmlFor="upload-qr">
                  <Button variant="contained" size="small" component="span">
                    Upload Foto QR
                  </Button>
                </label>
                {data.foto_qr && (
                  <img
                    src={URL.createObjectURL(data.foto_qr)}
                    alt="QR Preview"
                    width="100%"
                    style={{ marginTop: 10 }}
                  />
                )}
              </FormControl>

              

              <TextField
                type="text"
                size="small"
                fullWidth
                placeholder="Nomor Seri Cloud NVR"
                value={data.serial_number}
                onChange={(e) =>
                  setData({ ...data, serial_number: e.target.value })
                }
                sx={{ marginTop: 2 }}
              />

              {/* Upload Progress */}
              {progress > 0 && (
                <Box sx={{ width: "100%", marginTop: 2 }}>
                  <LinearProgress variant="determinate" value={progress} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    sx={{ mt: 1 }}
                  >
                    {progress}% uploaded
                  </Typography>
                </Box>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "rgba(65, 178, 87, 1)",
                  textTransform: "none",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  ":hover": {
                    backgroundColor: "rgba(65, 178, 87, 0.7)",
                  },
                }}
                size="medium"
              >
                Selanjutnya
              </Button>
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default LaporanInternet;
