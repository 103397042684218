import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import {
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Typography
} from '@mui/material';
import DownloadTable from './download';


import { headerData } from '../../../data/headerCostum';
import DeleteData from './delete';
import DownloadRounded from '@mui/icons-material/DownloadRounded';
const downloadColumns = [
    {
        id: 'kecamatan',
        label: 'Kecamatan',
    },
    {
        id: 'kelurahan',
        label: 'Kelurahan',
    },
    {
        id: 'nama_longwis',
        label: 'Nama Longwis',
    },
    {
        id: 'alamat',
        label: 'Alamat',
        minWidth: 150
    },
    {
        id: 'nama_pic',
        label: 'Nama PIC',
        minWidth: 100,
    },
    {
        id: 'kontak_pic',
        label: 'No Telpon',
        minWidth: 200,
    },
    {
        id: 'lokasi',
        label: 'Lokasi',
    },
];

const columns = [
    {
        id: 'kecamatan',
        label: 'Kecamatan',
        minWidth: 150
    },
    {
        id: 'kelurahan',
        label: 'Kelurahan',
        minWidth: 100,
    },
    {
        id: 'nama_longwis',
        label: 'Nama Longwis',
        minWidth: 200,
    },
    {
        id: 'alamat',
        label: 'Alamat',
        minWidth: 150,
    },
    {
        id: 'nama_pic',
        label: 'Nama PIC',
        minWidth: 150,
    },
    {
        id: 'kontak_pic',
        label: 'No Telpon',
        minWidth: 150,
    },
    {
        id: 'InternetLongwi',
        label: 'Serial Number ONT',
        minWidth: 150,
        format: (value) => {
            return(
                <Typography>
                    {value ? value.serial_number : "Belum Input"}
                </Typography>
            )
        }
    },
    {
        id: 'InternetLongwi',
        label: 'QR Code',
        minWidth: 150,
        format: (value) => {
            if(value.foto_qr){
                const filename = value.foto_qr.replace('.jpg', '');
                return <img width={50} alt={filename} src={process.env.REACT_APP_API_URL + "uploads/internet/"+filename} />
            }else{
                return "Tidak Ada"
            }
        }
    },
    {
        id: 'lokasi',
        label: 'Lokasi',
        minWidth: 150,
        format: (value) => {
            const coords = JSON.parse(value);
            return(
                <Typography>
                    {value ? <a href={'https://maps.google.com/maps?ll='+coords[0]+','+coords[1]+'&z=12&t=m&hl=en-US&gl=US&mapclient=embed&q=5%C2%B008%2750.6%22S%20119%C2%B025%2748.9%22E%20-5.147393%2C%20119.430255@'+coords[0]+','+coords[1]} target='_blank' rel="noreferrer">{"lat:"+coords[0]+", long:"+coords[1] }</a> : "Tidak Ada Lokasi"}
                </Typography>
            )
        }
    },
];


export default function LongwisInternet() {
    const [rows, setRows] = useState([])
    
    //Page Number
    let rowNumber = 0;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    //End of Page Number

    const [copyList, setCopyList] = useState(rows);

    //Filter
    const [kecamatanValue, setkecamatanValue] = useState('');
    const [kecamatanData, setkecamatanData] = useState([]);

    const getDataListLongwis = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/longwis", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                const filteredData = data.filter((item) => item.InternetLongwi !== null )
                setRows(filteredData)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const searchText = (searched) => {
        setCopyList(rows.filter((item) =>
            item.nama_longwis.toUpperCase().includes(searched.toUpperCase())
        ));
    }

    //Page handle
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    //End of page Handle

    //Filter Hanlde
    const getkecamatanamatanData = useCallback(() => {
        let kecamatan_list = [];
        rows.forEach((items) => {
            if (kecamatan_list.includes(items.kecamatan)) {
                return
            } else {
                kecamatan_list.push(items.kecamatan);
            }
        })
        setkecamatanData(kecamatan_list);
    }, [rows, setkecamatanData])


    const kecamatanHandle = useCallback((e) => {
        const data = rows.filter((item) =>
            item.kecamatan.toUpperCase().trim().includes(e.toUpperCase().trim())
        )
        setCopyList(data);
        setkecamatanValue(e);
    }, [setCopyList, setkecamatanValue, rows]);

    //End Of Filter handle

    useEffect(() => {
        if (rows.length === 0) {
            getDataListLongwis()
        } else {
            setCopyList(rows)
            getkecamatanamatanData()
        }
    },
        [getDataListLongwis, rows, getkecamatanamatanData]
    )

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Grid container xs={12} m={1} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item xs={6} md={5} lg={3}>
                    <TextField
                        id="outlined-textarea"
                        label="Cari"
                        placeholder="Nama atau kecamatan"
                        sx={{
                            width: '100%'
                        }}
                        size='small'
                        onInput={(e) => searchText(e.target.value)}
                    />
                </Grid>
                <Grid container item xs={6} md={5} lg={3} sx={{
                    paddingRight: 0.5
                }}
                    justifyContent={'end'}
                >
                    <Box sx={{ marginX: 0.5 }}>
                        <DownloadTable columns={downloadColumns} rows={copyList} filename={'data_ListLongwis.csv'} />
                    </Box>
                    {/* <Box sx={{ marginX: 0.5 }} >
                        <TambahAnak execute={getDataListLongwis} />
                    </Box> */}
                </Grid>
            </Grid>
            <Grid container xs={12} m={1} alignItems={'center'}>
                <Grid item xs={6} md={4} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel size='small' id="kecamatan-select-label">Kecamatan</InputLabel>
                        <Select
                            labelId="kecamatan-select-label"
                            id="kecamatan-select"
                            value={kecamatanValue}
                            label="Instansi"
                            onChange={(e) => kecamatanHandle(e.target.value)}
                            size='small'
                        >
                            {
                                kecamatanData.map((items) => {
                                    return <MenuItem value={items}>{items}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <TableContainer sx={{ maxHeight: '90vh' }}>
                <Table size='small' stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key={'no'}
                                align={'center'}
                                style={{ minWidth: 5 }}
                            >
                                {'No'}
                            </TableCell>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell
                                key={'aksi'}
                                align={'center'}
                                style={{ minWidth: 180 }}
                            >
                                {'Aksi'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {copyList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                rowNumber += 1;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        <TableCell
                                            align='center'
                                            key={'no'}>
                                            {rowNumber + page * rowsPerPage}
                                        </TableCell>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                        {/* <TableCell
                                            align='left'
                                            key={'kecamatan'}>
                                            {row.nama_pic ? row.nama_pic : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            key={'aksi'}
                                        >
                                            <Grid container item>
                                                {/* <EditDataAnak id={row.id} execute={getDataListLongwis} /> */}
                                                <Button
                                                    variant="contained"
                                                    onClick={() => !row.lokasi ? window.open("/#/download/"+row.id, "_blank") : window.open("/#/download/internet/"+row.id, "_blank")}
                                                    sx={{
                                                        backgroundColor: "rgba(65, 178, 87, 1)",
                                                        textTransform: "none",
                                                        fontWeight: 500,
                                                        color: "#FFFFFF",
                                                        ":hover": {
                                                            backgroundColor: "rgba(65, 178, 87, 0.7)",
                                                        },
                                                    }}
                                                    size="small"
                                                >
                                                    <DownloadRounded />
                                                </Button>
                                                <DeleteData id={row.id} execute={getDataListLongwis} />
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={copyList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}